import React from "react"
import {Link} from "gatsby"
import "../styles/404.css"
export default () => (
    <main class="error">
        <h1>404</h1>
        <p className="error-msg">
            :( Page not found. <Link to="/">Go home?</Link>
        </p>
    </main>
)